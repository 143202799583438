<template>
    <v-main class="login fill-height">
      <v-container fluid class="fill-height">

      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="6">

          <v-card class="elevation-12">

            <v-toolbar dark color="primary">
              <v-toolbar-title>{{$t('auth.titles.toolbar')}}</v-toolbar-title>
            </v-toolbar>

            <v-card-text>

              <v-form>
                <v-text-field
                  prepend-icon="far fa-user"
                  name="login"
                  :label="$t('auth.labels.email')"
                  type="text"
                  v-model="form.email">
                </v-text-field>

                <v-text-field
                  id="password"
                  prepend-icon="far fa-lock-alt"
                  name="password"
                  :label="$t('auth.labels.password')"
                  type="password"
                  @keyup.enter.native="login"
                  v-model="form.password">
                </v-text-field>

              </v-form>

              <AlertBar/>
            </v-card-text>

            <v-card-actions>

              <v-spacer/>
              <v-btn
                color="primary"
                :loading="isLoading.btnLogin"
                @click="login">
                {{$t('auth.buttons.login')}}
              </v-btn>

            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

  </v-main>
</template>

<script>
  export default {

    name: "Login",

    components: {

      AlertBar: () => import("@/components/Bars/AlertBar")
    },

    mixins: [],

    data() {

      return {
        form: {
          email: "",
          password: ""
        },
        isLoading: {
          btnLogin: false
        }
      }
    },

    methods:
    {
      login(event)
      {
        event.preventDefault();

        this.$store.commit('alert/hideAlert');

        if (this.form.username === "" || this.form.password === "")
          return this.$store.commit('alert/showError', this.$t('auth.errors.MISSING_FIELD'));

        this.isLoading.btnLogin = true;
        this.$http.post('/auth/login?api_key='+process.env.VUE_APP_API_KEY, this.form)
        .then(res =>
        {
          this.isLoading.btnLogin = false;
          this.form.password = '';

          // OK
          if (res.status === 200)
          {
            this.$session.start();
            this.$session.set('username', res.data.user.username);
            this.$session.set('role', res.data.user.role);
            this.$session.set('jwt', res.data.token);

            if (res.data.user._id)
              this.$session.set('id', res.data.user._id);


            this.$router.push({name: "dashboard"});

          }
        })
        .catch(err =>
        {
          this.form.password = '';
          this.isLoading.btnLogin = false;


          if (err.response && err.response.status === 400)
          {
            if (err.response.type === 'TOO_MANY_ATTEMPTS')
              this.$store.commit('alert/showError', this.$t('auth.errors.TOO_MANY_ATTEMPTS'));
            else
              this.$store.commit('alert/showError', this.$t('auth.errors.LOGIN_NOT_VALID'));
          }
          else
            this.$store.commit('alert/showError', "An error occurred");
        });
      }
    },

    created()
    {

    }
  }
</script>

<style>

  .login
  {
    background-color: #fafafa;
  }
</style>
